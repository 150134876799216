import { useState } from "react";
import classnames from "classnames";

import { loginUser } from "apis/serverRoutes";
import Button from "components/molecules/Button";
import Input from "components/molecules/Input";
import { onLoginSuccess } from "utils/auth";

import styles from "./index.module.scss";

const ERROR_TEXT = "Invalid user, please try again";

const Form = ({ className }: { className: string }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const onError = () => {
    setError(ERROR_TEXT);
    setPassword("");
    setEmail("");
  };

  const onLoginClick = () => {
    if (!email || !password) return;
    setLoading(true);
    loginUser({
      username: email,
      password: password,
    })
      .then((res: any) => {
        const token = res?.data?.token;
        if (token) onLoginSuccess(token);
        else onError();
      })
      .catch(() => {
        onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classnames(className, styles["container"])}>
      <div className={styles["content-holder"]}>
        <div className={styles["greeting"]}>Glad to see you back!</div>
        <div className={styles["title"]}>Login to continue</div>
        <div className={styles["subtitle"]}>
          Please enter the email is and password to access your account.
        </div>
        <Input
          className={styles["input"]}
          label={"Email"}
          placeholder={"eg. xyz@gamil.com"}
          value={email}
          onChange={(ev) => {
            setError("");
            setEmail(ev?.target?.value || "");
          }}
        />
        <Input
          className={styles["input"]}
          label={"Password"}
          type={"password"}
          placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxxx"}
          value={password}
          onChange={(ev) => {
            setError("");
            setPassword(ev?.target?.value || "");
          }}
        />
        <Button
          className={styles["login-button"]}
          disabled={!email || !password}
          onClick={onLoginClick}
          loading={loading}
        >
          Login
        </Button>
        {error && <div className={styles["error-text"]}>{error}</div>}
      </div>
    </div>
  );
};

export default Form;
