import { createIcon } from "utils/icons";

export const RotateRightIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) =>
  createIcon(
    <path
      d="M21 2V8M21 8H15M21 8L18.3597 5.63067C16.9787 4.25209 15.187 3.35964 13.2547 3.08779C11.3223 2.81593 9.35394 3.17941 7.64612 4.12343C5.93831 5.06746 4.58358 6.54091 3.78606 8.32177C2.98854 10.1026 2.79143 12.0944 3.22442 13.997C3.65742 15.8996 4.69707 17.61 6.18673 18.8704C7.67638 20.1308 9.53534 20.873 11.4835 20.9851C13.4317 21.0972 15.3635 20.5732 16.988 19.492C18.6124 18.4108 19.8414 16.831 20.4899 14.9907"
      stroke="#232323"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    { width: 24, height: 24, className, onClick },
  );

export const ZoomInIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) =>
  createIcon(
    <g fill="none" fillRule="evenodd">
      <path d="M6 0h24v24H6z" />
      <path
        d="M18.94 17.27 24 22.327 22.328 24l-5.059-5.06a10.586 10.586 0 0 1-6.636 2.326C4.763 21.266 0 16.503 0 10.633 0 4.763 4.764 0 10.633 0c5.87 0 10.633 4.764 10.633 10.633a10.586 10.586 0 0 1-2.326 6.636zm-2.37-.877a8.243 8.243 0 0 0 2.333-5.76c0-4.57-3.701-8.27-8.27-8.27-4.57 0-8.27 3.7-8.27 8.27 0 4.569 3.7 8.27 8.27 8.27a8.243 8.243 0 0 0 5.76-2.333l.177-.177zM9.452 9.452V5.907h2.363v3.545h3.544v2.363h-3.544v3.544H9.452v-3.544H5.907V9.452h3.545z"
        fill="#232323"
        fillRule="nonzero"
      />
    </g>,
    { width: 24, height: 24, className, onClick },
  );

export const ZoomOutIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) =>
  createIcon(
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M18.94 17.27 24 22.327 22.328 24l-5.059-5.06a10.586 10.586 0 0 1-6.636 2.326C4.763 21.266 0 16.503 0 10.633 0 4.763 4.764 0 10.633 0c5.87 0 10.633 4.764 10.633 10.633a10.586 10.586 0 0 1-2.326 6.636zm-2.37-.877a8.243 8.243 0 0 0 2.333-5.76c0-4.57-3.701-8.27-8.27-8.27-4.57 0-8.27 3.7-8.27 8.27 0 4.569 3.7 8.27 8.27 8.27a8.243 8.243 0 0 0 5.76-2.333l.177-.177zM9.452 9.452h5.907v2.363H5.907V9.452h3.545z"
        fill="#232323"
        fillRule="nonzero"
      />
    </g>,
    { width: 24, height: 24, className, onClick },
  );
