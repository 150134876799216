import { useEffect, useState } from "react";

import {
  getAssetDetails,
  getAssetList,
  saveOcrDetails,
} from "apis/serverRoutes";
import Button, { ButtonType } from "components/molecules/Button";
import Main from "components/Main";
import { OcrIcon } from "common/icons";
import Input from "components/molecules/Input";
import FileUploader from "components/molecules/FileUploader";
import Loader from "components/molecules/Loader";
import Select from "components/molecules/Select";
import ImageModal from "components/molecules/ImageModal";

import styles from "./index.module.scss";
import { UploadIcon } from "./icons";

const SUPPORTED_IMAGE_TYPE = ["JPG", "JPEG", "PNG", "GIF"];

interface DocumentImageUploaderProps {
  handleFileChange: (file: File) => void;
  title: string;
  image: File | null;
}

const DocumentImageUploader = ({
  handleFileChange,
  title,
  image,
}: DocumentImageUploaderProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<string>("");

  return (
    <div className={styles["uploaded-holder"]}>
      <ImageModal onClose={() => setModalImage("")} image={modalImage} />
      {image ? (
        <img
          src={URL.createObjectURL(image)}
          className={styles["uploaded-image"]}
          alt={"uploaded image"}
          onClick={() => setModalImage(URL.createObjectURL(image))}
        />
      ) : (
        <FileUploader
          handleChange={(file) => {
            setShowError(false);
            handleFileChange(file);
          }}
          onTypeError={() => setShowError(true)}
          className={styles["uploader-holder"]}
          fileTypes={SUPPORTED_IMAGE_TYPE}
        >
          <UploadIcon />
          <div className={styles["uploader-text"]}>{title}</div>
          <div className={styles["or-holder"]}>
            <div className={styles["line"]} />
            <div className={styles["or-text"]}>OR</div>
            <div className={styles["line"]} />
          </div>
          <Button buttonType={ButtonType.Outlined}>Browse Files</Button>
          {showError && (
            <div className={styles["upload-error"]}>
              Image should be in {SUPPORTED_IMAGE_TYPE.join(", ")} format.
            </div>
          )}
        </FileUploader>
      )}
    </div>
  );
};

const OcrPage = () => {
  const [frontImage, setFrontImage] = useState<File | null>(null);
  const [backImage, setBackImage] = useState<File | null>(null);
  const [assetList, setAssetList] = useState<string[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [ocrLoading, setOcrLoading] = useState<boolean>(false);
  const [ocrData, setOcrData] = useState<Record<string, string> | null>(null);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  useEffect(() => {
    getAssetList()
      .then((res: any) => {
        if (res?.data && Object.values(res?.data).length)
          setAssetList(Object.values(res.data));
      })
      .catch((err) => console.error(err));
  }, []);

  const onScanClick = () => {
    if (!selectedAsset || !frontImage) return;
    setOcrLoading(true);
    setOcrData(null);
    const formData = new FormData();
    formData.set("category", selectedAsset);
    formData.set("image", frontImage);
    if (backImage) formData.set("image1", backImage);
    getAssetDetails(formData)
      .then((res: any) => {
        if (res) setOcrData(res);
        else console.error("Something went wrong!!");
      })
      .catch((err) => console.error(err))
      .finally(() => setOcrLoading(false));
  };

  const onSaveClick = () => {
    if (!ocrData) return;
    setSaveLoading(true);
    saveOcrDetails(ocrData)
      .then(() => {
        console.log("Data saved successfully");
      })
      .catch((err) => console.error(err))
      .finally(() => setSaveLoading(false));
  };

  return (
    <Main>
      {assetList.length ? (
        <>
          <div className={styles["header"]}>
            <div className={styles["dropdown-holder"]}>
              <div className={styles["title"]}>OCR SCAN</div>
              <Select
                value={selectedAsset}
                options={assetList.map((asset) => ({
                  value: asset,
                  name: asset,
                }))}
                onOptionSelect={(val: string) => setSelectedAsset(val)}
              />
            </div>
            <div className={styles["cta-holder"]}>
              <Button
                buttonType={ButtonType.Outlined}
                className={styles["cta"]}
                onClick={() => {
                  setFrontImage(null);
                  setBackImage(null);
                  setOcrData(null);
                }}
                disabled={!frontImage && !backImage}
              >
                <OcrIcon className={styles["ocr-icon"]} />
                New Scan
              </Button>
              <Button
                className={styles["cta"]}
                disabled={!ocrData}
                onClick={onSaveClick}
                loading={saveLoading}
              >
                Save Details
              </Button>
              <Button
                className={styles["cta"]}
                disabled={!selectedAsset || !frontImage}
                onClick={onScanClick}
                loading={ocrLoading}
                loaderSize={16}
              >
                Scan now
              </Button>
            </div>
          </div>
          <div className={styles["content-holder"]}>
            <div className={styles["uploader-container"]}>
              <DocumentImageUploader
                image={frontImage}
                title={"Upload front of the document"}
                handleFileChange={setFrontImage}
              />
              <DocumentImageUploader
                image={backImage}
                title={"Upload back of the document"}
                handleFileChange={setBackImage}
              />
            </div>
            <div className={styles["form-holder"]}>
              <div className={styles["title"]}>Extracted Information</div>
              {ocrData ? (
                <div className={styles["ocr-data-holder"]}>
                  {Object.entries(ocrData).map((value) => (
                    <Input
                      label={value[0]}
                      value={value[1]}
                      className={styles["info"]}
                      onChange={(ev) => {
                        if (!ocrData) return;
                        setOcrData({ ...ocrData, [value[0]]: ev.target.value });
                      }}
                    />
                  ))}
                </div>
              ) : (
                <div>Your details will appear here</div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles["loader-holder"]}>
          <Loader loaderSize={40} color={"#463cff"} />
        </div>
      )}
    </Main>
  );
};

export default OcrPage;
