import styles from "./index.module.scss";

const Loader = ({
  loaderSize = 16,
  color = "#FFF",
}: {
  loaderSize?: number;
  color?: string;
}) => {
  return (
    <div
      style={{
        width: `${loaderSize}px`,
        height: `${loaderSize}px`,
        background: color,
      }}
      className={styles["loader"]}
    />
  );
};

export default Loader;
