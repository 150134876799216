export const ROUTES = {
  ocr: {
    path: "/ocr",
    auth: true,
  },
  ocrList: {
    path: "/ocr-list",
    auth: true,
  },
  loginPage: {
    path: "/login",
    auth: false,
  },
};
