import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { deleteOcrRecord, getOcrList } from "apis/serverRoutes";
import { OcrIcon } from "common/icons";
import Main from "components/Main";
import Button, { ButtonType } from "components/molecules/Button";
import Loader from "components/molecules/Loader";
import Modal from "components/molecules/Modal";
import { ROUTES } from "utils/routes";

import styles from "./index.module.scss";
import { DeleteIcon } from "./icons";

interface OcrDataModel {
  id: number;
  document_type: string;
  name: string;
  dob: string;
  agent_id: string;
  aadhaar_number: string;
  gender: string;
  address: string;
  father_name: string | null;
  pan_number: string | null;
  surname: string | null;
  given_name: string | null;
  passport_no: string | null;
  issue_date: string | null;
  expiry_date: string | null;
  nationality: string | null;
  place_of_birth: string | null;
  place_of_issue: string | null;
  mother_name: string | null;
  spouse_name: string | null;
  customer_name: string | null;
  account_number: string | null;
  bill_date: string | null;
  due_date: string | null;
  total_amount: string | null;
  created_at: string;
  updated_at: string;
}

const OcrListPage = () => {
  const navigate = useNavigate();

  const [ocrListData, setOcrListData] = useState<OcrDataModel[] | null>(null);
  const [deleteModalId, setDeleteModalId] = useState<number>(0);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [ocrModalData, setOcrModalData] = useState<OcrDataModel | null>(null);

  useEffect(() => {
    fetchOcrList();
  }, []);

  const fetchOcrList = () => {
    getOcrList()
      .then((res) => {
        if (res?.data?.data) {
          setOcrListData(res.data.data);
        } else console.error("Unable to fetch OCR list");
      })
      .catch((err) => console.error(err));
  };

  const removeOcrRecord = (ocrId: number) => {
    if (!ocrId) return;
    setDeleteLoader(true);
    deleteOcrRecord(ocrId)
      .then(() => {
        fetchOcrList();
        setDeleteModalId(0);
      })
      .catch((err) => console.error(err))
      .finally(() => setDeleteLoader(false));
  };

  return (
    <Main>
      <Modal
        open={!!deleteModalId}
        onClose={() => setDeleteModalId(0)}
        classNames={{
          modal: styles["modal"],
        }}
        showCloseIcon={false}
      >
        <div className={styles["delete-modal-title"]}>
          Are you sure you want to delete this entry?
        </div>
        <div className={styles["delete-cta-holder"]}>
          <Button
            buttonType={ButtonType.Outlined}
            onClick={() => setDeleteModalId(0)}
            className={styles["cta"]}
          >
            No
          </Button>
          <Button
            className={styles["cta"]}
            onClick={() => removeOcrRecord(deleteModalId)}
            loading={deleteLoader}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        open={!!ocrModalData}
        onClose={() => setOcrModalData(null)}
        classNames={{
          modal: styles["modal"],
        }}
      >
        {ocrModalData &&
          Object.entries(ocrModalData).map(([key, value]) =>
            value ? (
              <div className={styles["detail-modal-entry"]}>
                <div className={styles["key"]}>{key.split("_").join(" ")}</div>
                <div className={styles["value"]}>{value}</div>
              </div>
            ) : (
              <></>
            ),
          )}
      </Modal>
      <div className={styles["header"]}>
        <div className={styles["dropdown-holder"]}>
          <div className={styles["title"]}>OCR SCAN LIST</div>
        </div>
        <div className={styles["cta-holder"]}>
          <Button
            buttonType={ButtonType.Outlined}
            className={styles["cta"]}
            onClick={() => navigate(ROUTES.ocr.path)}
          >
            <OcrIcon className={styles["ocr-icon"]} />
            New Scan
          </Button>
        </div>
      </div>
      <div className={styles["content-holder"]}>
        {ocrListData === null ? (
          <div className={styles["loader-container"]}>
            <Loader loaderSize={32} color={"#463cff"} />
          </div>
        ) : (
          <>
            <div className={styles["ocr-item"]}>
              <div>Document Number</div>
              <div>Document Type</div>
              <div>Actions</div>
            </div>
            {ocrListData.map((ocr) => (
              <div
                className={styles["ocr-item"]}
                onClick={() => setOcrModalData(ocr)}
              >
                <div>{ocr.aadhaar_number}</div>
                <div>{ocr.document_type}</div>
                <div
                  className={styles["delete-cta"]}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setDeleteModalId(ocr.id);
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Main>
  );
};

export default OcrListPage;
