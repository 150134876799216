import { ButtonHTMLAttributes } from "react";
import classnames from "classnames";

import Loader from "components/molecules/Loader";

import styles from "./index.module.scss";

export enum ButtonType {
  Filled,
  Outlined,
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  buttonType?: ButtonType;
  loading?: boolean;
  loaderSize?: number;
  loaderColor?: string;
}

const Button = ({
  className,
  buttonType = ButtonType.Filled,
  loading,
  loaderSize,
  loaderColor,
  ...props
}: ButtonProps) => (
  <button
    {...props}
    className={classnames(
      className,
      styles[
        buttonType === ButtonType.Filled ? "filled-button" : "outlined-button"
      ],
      (props.disabled || loading) && styles["disabled-button"],
    )}
    disabled={props.disabled || loading}
  >
    {loading ? (
      <Loader loaderSize={loaderSize} color={loaderColor} />
    ) : (
      props.children
    )}
  </button>
);

export default Button;
