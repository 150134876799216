import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import classnames from "classnames";

import Button from "components/molecules/Button";
import Modal from "components/molecules/Modal";

import { ZoomInIcon, ZoomOutIcon, RotateRightIcon } from "./icons";
import styles from "./index.module.scss";

interface ImageModalProps {
  onClose: () => void;
  image: string;
  name?: string;
}

const ImageModal = ({ onClose, image, name }: ImageModalProps) => {
  const [rotate, setRotate] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [version, setVersion] = useState<number>(1);

  const imageElem = document.getElementById("cover-image");
  const imageHolder = document.getElementById("draggable-image-holder");

  const rotateImage = () => {
    if (rotate === 3) setRotate(0);
    else setRotate(rotate + 1);
  };

  const zoomIn = () => {
    if (zoom === 2) setZoom(2);
    else setZoom(zoom + 0.5);
  };

  const zoomOut = () => {
    if (zoom === 0.5) setZoom(0.5);
    else setZoom(zoom - 0.5);
  };

  const resetImage = () => {
    setRotate(0);
    setZoom(1);
    setVersion(version + 1);
  };

  useEffect(() => {
    if (!!image) resetImage();
    //eslint-disable-next-line
  }, [image]);

  useEffect(() => {
    if (imageElem && imageHolder) {
      imageElem.style.transform = `rotate(${rotate * 90}deg) scale(${zoom})`;
      if (zoom === 1 && rotate % 2 === 1) {
        if (imageElem.offsetWidth > imageHolder.offsetHeight) {
          imageElem.style.width = imageHolder.offsetHeight + "px";
        }
      } else {
        imageElem.style.width = "";
      }
    }
    //eslint-disable-next-line
  }, [zoom, rotate]);

  useEffect(() => {
    if (zoom !== 1) setZoom(1);
    if (rotate !== 0) setRotate(0);
    // eslint-disable-next-line
  }, [image]);

  const isDraggable = zoom !== 1 || rotate !== 0;

  return (
    <Modal
      open={!!image}
      onClose={onClose}
      center
      classNames={{
        modal: styles["modal-img-holder-container"],
        root: styles["img-modal-root"],
        overlay: styles["modal-overlay"],
        closeIcon: styles["close-icon"],
        closeButton: styles["close-button"],
      }}
    >
      <div className={styles["modal-image-holder"]}>
        <div
          className={styles["draggable-image-holder"]}
          id="draggable-image-holder"
        >
          <Draggable disabled={!isDraggable} key={version}>
            <div
              className={classnames(
                styles["image-holder"],
                isDraggable && styles["image-draggable"],
              )}
            >
              <img
                src={image}
                alt="cover"
                className={styles["cover-image"]}
                id="cover-image"
                draggable="false"
              />
            </div>
          </Draggable>
        </div>
        <div className={styles["actions-holder"]}>
          <div className={styles["image-controls"]}>
            <ZoomOutIcon
              className={classnames(
                styles["control-icon"],
                styles["zoom-mb"],
                zoom === 0.5 && styles["control-icon-disabled"],
              )}
              onClick={zoomOut}
            />
            <ZoomInIcon
              onClick={zoomIn}
              className={classnames(
                styles["control-icon"],
                styles["zoom-mb"],
                zoom === 2 && styles["control-icon-disabled"],
              )}
            />
            <RotateRightIcon
              className={styles["control-icon"]}
              onClick={rotateImage}
            />
            <Button
              className={
                zoom === 1 && rotate === 0
                  ? styles["disabled-reset-button"]
                  : styles["reset-button"]
              }
              onClick={resetImage}
              disabled={zoom === 1 && rotate === 0}
            >
              Reset
            </Button>
          </div>
          <Button
            onClick={async () => {
              const img = await fetch(image);
              const imageBlog = await img.blob();
              const imageURL = URL.createObjectURL(imageBlog);
              const link = document.createElement("a");
              link.href = imageURL;
              link.download = name || "image";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            className={styles["reset-button"]}
          >
            Download Image
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageModal;
