import { createIcon } from "utils/icons";

export const DeleteIcon = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) =>
  createIcon(
    <path
      d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
      fill="#D17758"
    />,
    { width: 16, height: 18, className, onClick },
  );
