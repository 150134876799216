import { SelectHTMLAttributes } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

export interface SelectOptions {
  value: string;
  name: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOptions[];
  onOptionSelect: (val: string) => void;
}

const Select = (props: SelectProps) => (
  <select
    {...props}
    className={classnames(styles["select"], props.className)}
    onChange={(ev) => props.onOptionSelect(ev.target.value)}
    value={props.value}
  >
    <option value="" selected disabled hidden>
      Choose here
    </option>
    {props.options.map((val) => (
      <option key={val.value} value={val.value}>
        {val.name}
      </option>
    ))}
  </select>
);

export default Select;
