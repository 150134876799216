import { Provider } from "react-redux";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import OcrPage from "components/OcrPage";
import OcrListPage from "components/OcrListPage";
import LoginPage from "components/LoginPage";
import NotFound from "components/NotFound";
import { ROUTES } from "utils/routes";
import store from "utils/redux/store";

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.loginPage.path} element={<LoginPage />} />
        <Route path={ROUTES.ocr.path} element={<OcrPage />} />
        <Route path={ROUTES.ocrList.path} element={<OcrListPage />} />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

export default App;
