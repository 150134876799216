import classnames from "classnames";

import { Logo } from "common/icons";

import styles from "./index.module.scss";
import { BottomRightIcon, TopLeftIcon } from "./icons";

const Banner = ({ className }: { className: string }) => {
  return (
    <div className={classnames(styles["container"], className)}>
      <TopLeftIcon className={styles["top-left-icon"]} />
      <BottomRightIcon className={styles["bottom-right-icon"]} />
      <div className={styles["content-holder"]}>
        <Logo />
        <div className={styles["title"]}>
          Welcome to <span>InfinitiAI</span>
        </div>
        <div className={styles["subtitle"]}>
          Smarter Compliance, Faster Decisions.
        </div>
      </div>
    </div>
  );
};

export default Banner;
