import Api from "./index";

interface LoginPayloadModel {
  username: string;
  password: string;
}

export const loginUser = (payload: LoginPayloadModel) =>
  Api.post("/api_login_view", payload);

export const getAssetList = () => Api.get("/document_type_api_view");

export const getOcrList = () => Api.get("/list_ocr_data");

export const deleteOcrRecord = (ocrId: number) =>
  Api.get(`/delete_ocr_data/${ocrId}`);

export const getAssetDetails = (payload: FormData) =>
  Api.postMultipart("/ocr_api_view", payload);

export const saveOcrDetails = (payload: Record<string, string>) =>
  Api.post("/save_ocr_data", payload);
