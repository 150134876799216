import classnames from "classnames";

import styles from "./index.module.scss";
import { HeaderLogo, ProfilePhoto } from "./icons";

const Header = ({ className }: { className?: string }) => (
  <div className={classnames(styles["container"], className)}>
    <div className={styles["brand-container"]}>
      <HeaderLogo />
      <div className={styles["org-name"]}>Dummy.co.pvt.ltd</div>
    </div>
    <div className={styles["user-container"]}>
      <ProfilePhoto className={styles["profile-photo"]} />
      <div className={styles["name"]}>Hello, Krishna</div>
    </div>
  </div>
);

export default Header;
