import { ReactNode } from "react";

import styles from "./index.module.scss";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header className={styles["header"]} />
      <Sidebar className={styles["sidebar"]} />
      <div className={styles["content-holder"]}>{children}</div>
    </>
  );
};

export default Layout;
