import { useLocation, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import classnames from "classnames";

import Button, { ButtonType } from "components/molecules/Button";
import { OcrIcon } from "common/icons";
import { onLogoutSuccess } from "utils/auth";
import { ROUTES } from "utils/routes";

import styles from "./index.module.scss";

interface TabsDataModel {
  name: string;
  icon: ReactNode;
  path: string;
}

const TABS_DATA: TabsDataModel[] = [
  {
    name: "OCR Scan",
    icon: <OcrIcon />,
    path: ROUTES.ocr.path,
  },
  {
    name: "OCR Records",
    icon: <OcrIcon />,
    path: ROUTES.ocrList.path,
  },
];

const Sidebar = ({ className }: { className?: string }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={classnames(styles["container"], className)}>
      <div className={styles["tabs-holder"]}>
        {TABS_DATA.map((tab) => (
          <div
            key={tab.name}
            className={
              styles[
                location.pathname === tab.path ? "active-tab-item" : "tab-item"
              ]
            }
            onClick={() => navigate(tab.path)}
          >
            <OcrIcon />
            <div className={styles["tab-name"]}>{tab.name}</div>
          </div>
        ))}
      </div>
      <Button
        className={styles["logout-cta"]}
        onClick={() => onLogoutSuccess()}
        buttonType={ButtonType.Outlined}
      >
        Logout
      </Button>
    </div>
  );
};

export default Sidebar;
