import { ReactNode } from "react";
import { Modal as ReactModal } from "react-responsive-modal";

interface Props {
  open: boolean;
  onClose: (ev?: any) => void;
  children: ReactNode;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
  showCloseIcon?: boolean;
  center?: boolean;
  classNames?: {
    root?: string;
    overlay?: string;
    overlayAnimationIn?: string;
    overlayAnimationOut?: string;
    modalContainer?: string;
    modal?: string;
    modalAnimationIn?: string;
    modalAnimationOut?: string;
    closeButton?: string;
    closeIcon?: string;
  };
}

const Modal = ({
  open,
  onClose,
  closeOnEsc,
  closeOnOverlayClick = true,
  showCloseIcon = true,
  children,
  center = true,
  classNames,
}: Props) => (
  <ReactModal
    open={open}
    focusTrapped={false}
    onClose={onClose}
    showCloseIcon={showCloseIcon}
    closeOnEsc={closeOnEsc}
    center={center}
    closeOnOverlayClick={closeOnOverlayClick}
    classNames={classNames}
  >
    {children}
  </ReactModal>
);

export default Modal;
