import Main from "components/Main";

import styles from "./index.module.scss";
import Banner from "./Banner";
import Form from "./Form";

const LoginPage = () => {
  return (
    <Main className={styles["container"]}>
      <Banner className={styles["banner"]} />
      <Form className={styles["form"]} />
    </Main>
  );
};

export default LoginPage;
