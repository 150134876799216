import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthStateModel {
  accessToken: string | null;
}

const initialState: AuthStateModel = {
  accessToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    resetAuth: (state) => {
      state.accessToken = "";
    },
  },
});

export const { setAccessToken, resetAuth } = authSlice.actions;

export default authSlice.reducer;
