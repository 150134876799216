import { createIcon } from "utils/icons";

export const TopLeftIcon = ({ className }: { className?: string }) =>
  createIcon(
    <circle
      opacity="0.3"
      cx="-39"
      cy="-50"
      r="185"
      stroke="#E9E6DF"
      strokeWidth="6"
    />,
    { width: 149, height: 138, className },
  );

export const BottomRightIcon = ({ className }: { className?: string }) =>
  createIcon(
    <path
      d="M4.86413 224C0.488992 182.291 12.8013 140.55 40.1236 104.463C67.4459 68.3767 108.45 39.6997 157.549 22.3389C206.648 4.97809 253.952 -0.22231 307 7.44617"
      stroke="#E9E6DF"
      strokeWidth="7"
    />,
    { width: 307, height: 170, className },
  );
