import { configureStore } from "@reduxjs/toolkit";

import authSlice, { AuthStateModel } from "./slice/auth";

export interface ReduxStateModel {
  auth: AuthStateModel;
}

export default configureStore({
  reducer: {
    auth: authSlice,
  },
});
