import { CSSProperties, ReactNode } from 'react';

type Opts = {
  width: number;
  height: number;
  style?: CSSProperties;
  fill?: string;
  className?: string;
  onClick?: () => void;
  id?: string;
};

export const createIcon = (d: string | ReactNode, opts: Opts) => {
  const { width, height, style, fill, className, onClick, ...props } = opts;
  if (style || className) {
    return (
      <div style={style} className={className} onClick={onClick} {...props}>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: 'block' }}
          fill={fill || 'none'}
        >
          {typeof d === 'string' ? <path fill="currentColor" d={d} /> : d}
        </svg>
      </div>
    );
  } else {
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}
        fill={fill || 'none'}
        onClick={onClick}
      >
        {typeof d === 'string' ? <path fill="currentColor" d={d} /> : d}
      </svg>
    );
  }
};
