import store from "utils/redux/store";
import { resetAuth, setAccessToken } from "utils/redux/slice/auth";
import { removeAccessTokenLs, setAccessTokenLs } from "utils/localstorage";

export const onLoginSuccess = (token: string) => {
  setAccessTokenLs(token);
  store.dispatch(setAccessToken(token));
};

export const onLogoutSuccess = () => {
  removeAccessTokenLs();
  store.dispatch(resetAuth());
};
