import { createIcon } from "utils/icons";

export const UploadIcon = ({ className }: { className?: string }) =>
  createIcon(
    <>
      <g id="upload" clipPath="url(#clip0_875_7702)">
        <path
          id="Vector"
          d="M33.4417 3.12061H14.1743V11.1106H37.5567V7.23402C37.5567 4.96567 35.7107 3.12061 33.4417 3.12061Z"
          fill="#E3E3E3"
        />
        <path
          id="Vector_2"
          d="M22.5352 12.3403H0V4.92636C0 2.20972 2.21068 0 4.92828 0H12.1336C12.8497 0 13.5396 0.150925 14.1664 0.434509C15.0418 0.828964 15.7939 1.47913 16.3213 2.3286L22.5352 12.3403Z"
          fill="#999999"
        />
        <path
          id="Vector_3"
          d="M42 13.9999V37.8812C42 40.1525 40.1511 41.9998 37.8789 41.9998H4.12111C1.84891 41.9998 0 40.1525 0 37.8812V9.88037H37.8789C40.1511 9.88037 42 11.7283 42 13.9999Z"
          fill="#363636"
        />
        <path
          id="Vector_4"
          d="M42 13.9999V37.8812C42 40.1525 40.1511 41.9998 37.8789 41.9998H21V9.88037H37.8789C40.1511 9.88037 42 11.7283 42 13.9999Z"
          fill="#505050"
        />
        <path
          id="Vector_5"
          d="M32.048 25.94C32.048 32.0325 27.0919 36.9889 21.0001 36.9889C14.9083 36.9889 9.95215 32.0325 9.95215 25.94C9.95215 19.8486 14.9083 14.8921 21.0001 14.8921C27.0919 14.8921 32.048 19.8486 32.048 25.94Z"
          fill="#E7ECFC"
        />
        <path
          id="Vector_6"
          d="M32.0479 25.94C32.0479 32.0325 27.0918 36.9889 21 36.9889V14.8921C27.0918 14.8921 32.0479 19.8486 32.0479 25.94Z"
          fill="#E3E3E3"
        />
        <path
          id="Vector_7"
          d="M24.5612 26.0753C24.3308 26.2704 24.0485 26.3656 23.7688 26.3656C23.4185 26.3656 23.0705 26.2173 22.827 25.9282L22.2307 25.2213V29.8494C22.2307 30.5287 21.6795 31.0799 21.0002 31.0799C20.3209 31.0799 19.7698 30.5287 19.7698 29.8494V25.2213L19.1734 25.9282C18.7344 26.4476 17.9587 26.514 17.4392 26.0753C16.9201 25.6373 16.8535 24.8612 17.2915 24.3418L19.7271 21.4543C20.0447 21.0788 20.508 20.8628 21.0002 20.8628C21.4924 20.8628 21.9558 21.0788 22.2733 21.4543L24.7089 24.3418C25.147 24.8612 25.0803 25.6373 24.5612 26.0753Z"
          fill="#989898"
        />
        <path
          id="Vector_8"
          d="M24.561 26.0753C24.3306 26.2704 24.0483 26.3656 23.7686 26.3656C23.4183 26.3656 23.0703 26.2173 22.8268 25.9282L22.2305 25.2213V29.8494C22.2305 30.5287 21.6793 31.0799 21 31.0799V20.8628C21.4922 20.8628 21.9555 21.0788 22.2731 21.4543L24.7087 24.3418C25.1467 24.8612 25.0801 25.6373 24.561 26.0753Z"
          fill="#7A7A7A"
        />
      </g>
      <defs>
        <clipPath id="clip0_875_7702">
          <rect width="42" height="42" fill="white" />
        </clipPath>
      </defs>
    </>,
    { width: 42, height: 42, className },
  );
